import React from 'react';

const ServersComponent: React.FC = () => {
    return (
        <div>
            <h2>Servers Component</h2>
            <p>This is the servers component.</p>
        </div>
    );
}

export default ServersComponent;